<template>
    <div class="Shine">
        <p class="p justify">
            SHINE是基于密度泛函理论和非平衡格林函数方法的量子力学程序，采用实空间投影缀加平面波方法描述离子原子核和价电子的原子轨道基，通过均匀实空间网格表示电子波函数，具有很强的计算收敛性和很高的并行计算能力，适合解决纳米材料的诸多性质。
        </p>
        <el-tag
            effect="dark"
        >
            计算对象
        </el-tag>
        <p class="p justify">
            SHINE能够计算包含周期性和开放边条件的气相分子、分子结、团簇、纳米、晶体等材料结构，能够处理金属、半导体、氧化物或者多种结构混合的超晶格及其含有缺陷的原子等体系。
        </p>
        <b-row align-h="center">
            <b-col
                lg="7"
                cols="12"
                class="text-center"
            >
                <img
                    class="img-info"
                    src="@/assets/images/shine/shine-1.png"
                >
            </b-col>
        </b-row>
        <el-tag
            effect="dark"
        >
            计算系统
        </el-tag>
        <b-row align-h="center">
            <b-col
                lg="7"
                cols="12"
                class="text-center"
            >
                <img
                    class="img-info"
                    src="@/assets/images/shine/shine-2.png"
                >
            </b-col>
        </b-row>
        <el-tag
            effect="dark"
        >
            计算功能
        </el-tag>
        <b-row align-h="center">
            <b-col
                lg="10"
                cols="12"
                class="text-center"
            >
                <img
                    class="img-info"
                    src="@/assets/images/shine/shine-3.png"
                >
            </b-col>
        </b-row>
        <el-tag
            effect="dark"
        >
            计算系统示例
        </el-tag>
        <b-row align-h="between">
            <b-col
                cols="12"
                lg="5"
                class="shine-img-col"
            >
                <div class="shine-img-col-div">
                    <p class="p-title">
                        量子电容器
                    </p>
                    <img
                        class="img-info"
                        src="@/assets/images/shine/shine-4.png"
                        style="width:70%"
                    >
                    <p class="p-desc">
                        描述：钠BCC结构在5V偏压下的电子态密度和电压降分布。
                    </p>
                </div>
            </b-col>
            <b-col
                cols="12"
                lg="5"
                class="shine-img-col"
            >
                <div class="shine-img-col-div">
                    <p class="p-title">
                        非平衡态原子力
                    </p>
                    <img
                        class="img-info"
                        src="@/assets/images/shine/shine-5.png"
                        style="width:60%"
                    >
                    <p class="p-desc">
                        描述：Au/N2/Au结构，偏压下的投影态密度和偏压下的原子力。
                    </p>
                </div>
            </b-col>
            <b-col
                cols="12"
                lg="5"
                class="shine-img-col"
            >
                <div class="shine-img-col-div">
                    <p class="p-title">
                        静电门压控制系统
                    </p>
                    <img
                        class="img-info"
                        src="@/assets/images/shine/shine-6.png"
                        style="width:60%"
                    >
                    <p class="p-desc">
                        描述：Au/Anthraquinone/Au结构，门压对电势的影响和栅压下的透射系数。
                    </p>
                </div>
            </b-col>
            <b-col
                cols="12"
                lg="5"
                class="shine-img-col"
            >
                <div class="shine-img-col-div">
                    <p class="p-title">
                        自旋输运体系
                    </p>
                    <img
                        class="img-info"
                        src="@/assets/images/shine/shine-7.png"
                        style="width:55%"
                    >
                    <p class="p-desc">
                        描述：ZGNR输运体系，0、2.4V偏压下的自旋投影态密度。
                    </p>
                </div>
            </b-col>
            <b-col
                cols="12"
                lg="5"
                class="shine-img-col"
            >
                <div class="shine-img-col-div">
                    <p class="p-title">
                        多端输运体系
                    </p>
                    <img
                        class="img-info"
                        src="@/assets/images/shine/shine-8.png"
                        style="width:70%"
                    >
                    <p class="p-desc">
                        描述：C60-六端输运体系，费米能级上的实空间态密度和平均有效势分布。
                    </p>
                </div>
            </b-col>
        </b-row>
        <el-tag
            effect="dark"
        >
            计算功能示例
        </el-tag>
        <b-row align-h="between">
            <b-col
                cols="12"
                lg="5"
                class="shine-img-col"
            >
                <div class="shine-img-col-div">
                    <p class="p-title">
                        材料结构优化
                    </p>
                    <img
                        class="img-info"
                        src="@/assets/images/shine/shine-9.png"
                        style="width:50%"
                    >
                    <p class="p-desc">
                        描述：通过DFT方法优化孤立的分子结构。
                    </p>
                </div>
            </b-col>
            <b-col
                cols="12"
                lg="5"
                class="shine-img-col"
            >
                <div class="shine-img-col-div">
                    <p class="p-title">
                        分子轨道空间分布
                    </p>
                    <img
                        class="img-info"
                        src="@/assets/images/shine/shine-10.png"
                        style="width:60%"
                    >
                    <p class="p-desc">
                        描述：分子轨道的HOMO-LUMO能级空间分布。
                    </p>
                </div>
            </b-col>
            <b-col
                cols="12"
                lg="5"
                class="shine-img-col"
            >
                <div class="shine-img-col-div">
                    <p class="p-title">
                        吸收光谱性质
                    </p>
                    <img
                        class="img-info"
                        src="@/assets/images/shine/shine-11.png"
                        style="width:65%"
                    >
                    <p class="p-desc">
                        描述：通过时间传播和线性响应方法计算CH4分子的吸收光谱。
                    </p>
                </div>
            </b-col>
            <b-col
                cols="12"
                lg="5"
                class="shine-img-col"
            >
                <div class="shine-img-col-div">
                    <p class="p-title">
                        光电子界面
                    </p>
                    <img
                        class="img-info"
                        src="@/assets/images/shine/shine-12.png"
                        style="width:65%"
                    >
                    <p class="p-desc">
                        描述：光电子界面的计算对比：实验 & DFT方法 & TDDFT方法。
                    </p>
                </div>
            </b-col>
            <b-col
                cols="12"
                lg="5"
                class="shine-img-col"
            >
                <div class="shine-img-col-div">
                    <p class="p-title">
                        输运性质
                    </p>
                    <img
                        class="img-info"
                        src="@/assets/images/shine/shine-13.png"
                        style="width:70%"
                    >
                    <p class="p-desc">
                        描述：门压控制下的电导和电子透射谱性质。
                    </p>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
  name: 'Shine',
  data () {
        return {

        }
      },
      methods: {

      },
      created () {
        this.$emit('contentTitle', 'SHINE')
      }
}
</script>
<style lang="less" scoped>
.Shine{
    margin-bottom:6.25rem;
    .shine-img-col{
      border: 1px solid #d7d7d7;
      margin: 1.5625rem 0;
      padding: 0 1.875rem;
      .shine-img-col-div{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .img-info{
          height: auto;
        }
        .p-desc{
          text-align: left;
          font-size: 0.875rem;
          margin-top: 1rem;
        }
        .p-title{
          font-weight: 600;
          font-size: 1rem;
        }
        }
    }
    /deep/.el-tag--dark{
      margin-top:3.125rem!important;
    }
    @media only screen and (min-width: 576px) and (max-width: 769px){
        .shine-img-col{
              height:500px;
          }
    }
    @media only screen and (min-width: 375px) and (max-width: 575.98px){
        .shine-img-col{
              height:330px;
          }
    }
     @media (max-width: 320px) {
          .shine-img-col{
              height:290px;
          }
    }
    @media (min-width: 1400px) {
          .shine-img-col{
              min-height:400px;
          }
    }
     @media only screen and (min-width: 1200px) and (max-width: 1399.98px){
        .shine-img-col{
              height:300px;
          }
    }
     @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
        .shine-img-col{
              height:300px;
          }
    }
    @media only screen and (min-width: 769px) and (max-width: 991.98px){
         .shine-img-col{
              height:500px;
          }
    }
}
</style>
